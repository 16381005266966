.umi-NotFound-wrapper {
  font-size: 18px;
  font-family: "Helvetica Neue", "Luxi Sans", "DejaVu Sans", Tahoma, "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}
.umi-NotFound-wrapper code {
  background: #7898f3;
}
.umi-NotFound-wrapper h1 {
  font-size: 40px;
  background: #78f191;
  margin-bottom: 30px;
}
.umi-NotFound-wrapper h2 {
  color: #c803fd;
  font-size: 22px;
  margin-top: 40px;
}
.umi-NotFound-wrapper ul {
  margin: 0;
  padding: 0;
  margin-left: 40px;
}
